<div class="grid align__item">
  <div class="register" appErrorDirective [errorMessage]="errorServerMessage">
    <div class="logo"></div>
    <h2>{{!linkSent ? 'Forgotten Password' : 'An email with reset link was sent! Please check your email!'}}</h2>
    <form (ngSubmit)="emailLink()"
          [formGroup]="form"
          *ngIf="!linkSent"
          method="post"
          class="form">

      <div class="form__field">
        <input type="email"
                (keyup)="errorServerMessage = undefined"
               formControlName="email"
               placeholder="youremail@example.com">
      </div>

      <div class="form__field">
        <input type="submit"
        [disabled]="!form.valid"
               value="Send me a link!">
      </div>

    </form>
    <p>Changed your mind? <a [routerLink]="['/login']"> login? </a></p>
  </div>
</div>
