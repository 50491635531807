import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private httpClient: HttpClient) {}

  login(username: string, password: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let doLoginRequest = await this.httpClient
          .post('https://id.akovachev.com/api/login', {
            username: username,
            password: password,
          })
          .toPromise();
        resolve(doLoginRequest);
      } catch (e) {
        reject(e);
      }
    });
  }

  resetPassword(username: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let doSendLinkRequest = await this.httpClient
          .post('https://id.akovachev.com/api/generateForgottenPasswordLink', {
            username: username,
          })
          .toPromise();

        resolve(doSendLinkRequest);
      } catch (e) {
        reject(e);
      }
    });
  }

  changePassword(newPassword: string, passwordChangeToken: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let changePasswordRequest = await this.httpClient
          .post('https://id.akovachev.com/api/changePassword', {
            password: newPassword,
            changePasswordToken: passwordChangeToken,
          })
          .toPromise();

        resolve(changePasswordRequest);
      } catch (e) {
        reject(e);
      }
    });
  }
}
