import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService
  ) {}

  minLength:number = 8
  newPassword = new FormControl('', [Validators.required, Validators.minLength(this.minLength)]);
  passwordChanged = false;
  cpToken = '';

  errorServerMessage!: string | undefined;

  ngOnInit(): void {
    this.route.params.subscribe((d) => {
      this.cpToken = d.token;
    });

    console.log(this.newPassword.errors)
  }

  change() {
    this.loginService
      .changePassword(this.newPassword.value, this.cpToken)
      .then((d) => {
        this.passwordChanged = true;
      }).catch(e => {
        this.errorServerMessage = e.error.message
      });
  }
}
