  <div class="grid align__item">
    <div class="register" appErrorDirective [errorMessage]="errorServerMessage">
      <div class="logo"></div>
      <h2>Login</h2>
      <form (ngSubmit)="login()"
            method="post"
            class="form">

        <div class="form__field">
          <input type="email"
                 [formControl]="user"
                 placeholder="youremail@example.com">
        </div>

        <div class="form__field">
          <input type="password"
          placeholder="************"
                 [formControl]="password">
        </div>

        <div class="form__field">
          <input type="submit"
                [disabled]="!redirectUrl || user.invalid || password.invalid"
                 value="Login">
        </div>

      </form>
      <p class="redirect-link" >
            <span *ngIf="redirectUrl; else error">
              Redirect Link:
              <a [href]="redirectUrl">{{redirectUrl}}</a>
            </span>
          <ng-template #error>
            <span>No Redirect URL Provided! Upon successful login you won't be redirected to your page</span>
          </ng-template>

      </p>
      <p><a [routerLink]="['/forgotten-password']">Forgotten password? </a></p>

    </div>

  </div>
