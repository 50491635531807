import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private loginService: LoginService, private activatedRoute: ActivatedRoute) { }
  hide=true;

  user = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required, Validators.minLength(8)]);
  redirectUrl!: string | undefined;
  errorServerMessage!: string | undefined;

  async login() {
    this.errorServerMessage = undefined
    try {
      let loginUser = await this.loginService.login(this.user.value, this.password.value)

      if(this.redirectUrl) {
        let redirect = this.redirectUrl + '?code=' + btoa(JSON.stringify(loginUser))
        window.location.href = redirect
      }

    } catch(e) {
      this.errorServerMessage = e.error.message;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(d => {
      this.redirectUrl = d.redirect ?  decodeURIComponent(d.redirect) : undefined;

      console.log(this.redirectUrl)
    })
  }
}
