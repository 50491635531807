<div class="grid align__item">
  <div class="register" appErrorDirective [errorMessage]="errorServerMessage">
    <div class="logo"></div>
    <h2>{{!passwordChanged ? 'Change password!': 'Your password was successfully changed!'}}</h2>
    <form (ngSubmit)="change()"
          *ngIf="!passwordChanged"
          method="post"
          class="form">

      <div class="form__field">
        <input type="password"
               [formControl]="newPassword"
               placeholder="Your New Password">
               <div class="hint">Password should be at least {{minLength}} characters</div>
      </div>

      <div class="form__field">
        <input type="submit"
                [disabled]="newPassword.invalid"
               value="Change my password">
      </div>

    </form>
    <p>Changed your mind? <a [routerLink]="['/login']"> login? </a></p>
  </div>
</div>
