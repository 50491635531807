import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {

  constructor(private loginService: LoginService) { }

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
  })

  errorServerMessage!: string | undefined;

  linkSent = false;
  ngOnInit(): void {
  }

  emailLink() {
    console.log(this.form.get('email')?.value)
    this.loginService.resetPassword(this.form.get('email')?.value).then((d) => {
      console.log('password reset link send!')
      this.linkSent = true;
    }).catch(e => {
      this.errorServerMessage = e.error.message;
    })
  }

}
